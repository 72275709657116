<template>
	<div class="supplyGoods">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="我的供货商品" name="1"></el-tab-pane>
			<el-tab-pane label="供货信息设置" name="2"></el-tab-pane>
		</el-tabs>

		<div v-if="activeName==1">
			<div>
				<el-button type="text" @click="handleTablePromote">供货商品展示码</el-button>
			</div>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" placeholder="商品名称、编码、规格条形码" style="width:250px;margin-right: 10px;" clearable
					@keyup.enter.native="goodsFilter"></el-input>
				</div>

				<div class="filter-item">
					<label class="label">商品分组: </label>
					<el-select v-model="goodsType" placeholder="请选择" style="width: 250px;margin-right: 10px;" clearable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in productSortList" :key="item.Id" :label="item.GroupName" :value="item.Id">
						</el-option>
					</el-select>
				</div>

				<div class="filter-item">
					<label class="label">商品品牌: </label>
					<el-select v-model="brandType" placeholder="请选择" style="width: 250px;" clearable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in productList" :key="item.Id" :label="item.BrandName" :value="item.Id">
						</el-option>
					</el-select>
				</div>

				<div class="filter-item">
					<el-button type="primary" @click="goodsFilter">查询</el-button>
				</div>
			</div>

			<div class="table-container">
				<el-table :data="groupData" style="width: 100%;" @sort-change="sortChange" v-loading="loading" ref="multipleTable">

					<el-table-column label="商品" width="450">
						<template slot-scope="scope">
							<div class="product-info" style="align-items:flex-start">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>
										<div style="width:300px;">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
										</div>
									</div>
									<div style="color:#909399">{{scope.row.ProductNo}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="DistributorCount" label="分销商家"></el-table-column>
					<el-table-column label="供货价">
						<template slot-scope="scope">
							<div style="color: red;">{{scope.row.SupplierPrice}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="Stock" sortable label="库存"></el-table-column>
					<el-table-column prop="DistributorSellCount" sortable label="分销销量"></el-table-column>
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination v-if="Total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
					:page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
					</el-pagination>
				</div>

			</div>
		</div>
		<div v-else style="margin-top:20px" v-loading="activeName2loading">
			<el-form :model="ruform" :rules="rules" ref="ruform" label-width="180px">
				<div class = "grayfont" style="margin:10px 0px 10px 95px">供货信息展示：当分销商在供货市场查看您的商品时，同时可查看到您的相关信息</div>
				<el-form-item label="客服电话：">
					<div class = "flexRow">
						<el-input v-model="ruform.CustomerServicePhoneAreaCode" style="width:100px" maxlength="5" placeholder="区号"></el-input>
						<span style="margin:0px 10px">-</span>
						<el-form-item prop="CustomerServicePhone" :rules="rules.CustomerServicePhone" label="">
							<el-input v-model="ruform.CustomerServicePhone" style="width:250px" maxlength="11" placeholder="请输入联系电话或手机号"></el-input>
						</el-form-item>
					</div>
				</el-form-item>
				<el-form-item label="客服微信：">
					<el-input v-model="ruform.CustomerServiceWx" style="width:375px" ></el-input>
				</el-form-item>
				<el-form-item label="简介：">
					<el-input type="textarea" v-model="ruform.SupplierIntroduction" style="width:375px" :rows="4" maxlength="100" placeholder="最多可输入100字"></el-input>
				</el-form-item>
				<el-form-item label="供货说明与资质详情：">
					<quill-editor ref="myTextEditor" v-model="ruform.SupplierIllustrate" :options="quillOption"
					 style="width:100%;height:300px;margin-bottom:100px"></quill-editor>
				</el-form-item>
			</el-form>

			<div class ="bottomcard">
				<el-button style="margin-left:5%" type="primary" @click="ruformconfirm('ruform')">保存</el-button>
			</div>
		</div>
      
		<batch-cods  :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" :title='"供货商品展示码:"' @cancel="weiCodeCancel"></batch-cods>

	</div>
</template>

<script>
	import config from '@/config/index'
    import batchCods from "@/components/bathCodes"
	import {
		productBrandList,
		productGroupList,
		mallsupplierInfoSave,
		mallsupplierInfoInit
	} from '@/api/goods'
	import {supplierProductList} from '@/api/TurnTomySelf'
	import {quillEditor} from 'vue-quill-editor'
	import quillConfig from '@/components/quill-config.js'
	export default {
		components: {
			batchCods,
			quillEditor,
		},
		data() {
			var checkphone = (rule, value, callback) => {
        if(this.ruform.CustomerServicePhoneAreaCode ){
					if(!value){
						callback(new Error('请输入联系电话或手机号'))
					}else{
						callback()
					}
				}else{
					callback()
				}
      };
			return {
				activeName:'1',
                weiCodeShow: false,
				weiCodeData: {},
				searchKey: '',
				goodsType:null,
				productSortList: [],
				brandType:null,
				productList: [],
				OrderBy: '',
				IsAsc: 0,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData:[],
				loading:false,
				imgUrl: config.IMG_BASE,
				activeName2loading:false,
				ruform:{
					CustomerServicePhoneAreaCode:'',
					CustomerServicePhone:'',
					CustomerServiceWx:'',
					SupplierIntroduction:'',
					SupplierIllustrate:'',
				},
				rules:{
					CustomerServicePhone:[
						{ validator: checkphone, trigger: 'blur' }
					]
				},
				quillOption:quillConfig,
			}
		},
		beforeMount() {
			this.getInfor()
			this.getList()
		},
		methods: {
			ruformconfirm(formName){
				this.$refs[formName].validate((valid) => {
          if (valid) {
						this.tosave()
          } else {
            return false;
          }
        });
			},
			async tosave(){
				this.activeName2loading = true
				try{
					let data = {SupplierInfoDto:JSON.parse(JSON.stringify(this.ruform))}
					let res = await mallsupplierInfoSave(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
					}
				}finally{
					this.activeName2loading = false
				}
			},
			async getsuppliermsg(){
				this.activeName2loading = true
				try{
					let res = await mallsupplierInfoInit()
					if(res.IsSuccess){
						this.ruform = res.Result
					}
				}finally{
					this.activeName2loading = false
				}
			},
			handleClick(e){
				if(e.name==1){
					this.getInfor()
					this.getList()
				}else{
					this.getsuppliermsg()
				}
			},
			// 推广-优惠券
			handleTablePromote(){
        let id = 123
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pageB/pages/pwLogin/pwLogin',
					Scene: `supplier=1`
				}
			},
			weiCodeCancel() {
				this.weiCodeShow = false;
			},
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.productList = result.Result

					let result1 = await productGroupList()
					this.productSortList = result1.Result

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWord:this.searchKey,
						ProductGroupId: this.goodsType,
						ProductBrandId: this.brandType, // 品牌id
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					let result = await supplierProductList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];


				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			// 排序
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>

	.supplyGoods {
		padding: 10px;
		background: #fff;
	}
	.grayfont{
		line-height: 1.5;
		font-size: 12px;
		color: #999999;
		word-break: break-all;
	}
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.bottomcard{
		position: fixed;
		left: 0px;
		right: 0px;
		bottom: 0px;
		height: 100px;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
	}

</style>
